import React, { useEffect, useRef, useState } from 'react';
import TaskDetails from './TaskDetailsModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { requestGetFetch, requestGetFetchByOptions } from '../../api/request';

import { MAIN_URL, TASKS } from '../../api/url';
import ProjectPagination from '../../components/Pagination';
import { useAuth } from '../../hooks/useAuth';
import { limitItemsOptions, ShiftsTimeLino } from '../../utils/constants';
import {
  getActiveMaterialFromCache,
  timestampToDateTime,
  writeToCache,
} from '../../utils/functions';
import NewRollModal from './NewRollModal';
import Table from '../../components/Table';
import TaskFilterContainer from './TaskFilterContainer';
import Tab from '../../components/Tab';
import { TaskMetric, TaskMetricsServerResponse } from '../../types/models/task';
import ShiftCheckTable from '../../components/ShiftCheckTable';
import { NumericLiteral } from 'typescript';

export const TASK_STATUSES = {
  WAITING: 'В ожидании',
  IN_PROGRESS: 'Незавершённые',
  DONE: 'Выполненные',
};

export default function Task() {
  const {
    accessToken,
    refreshToken,
    userId,
    controlPointId,
    channelWork,
    typeRoute,
    setTypeRoute,
  }: any = useAuth();
  const navigate = useNavigate();

  const hasMirror = getActiveMaterialFromCache(controlPointId, 'hasMirror');

  const [taskDetailsContainerShow, setTaskDetailsContainerShow] =
    useState(false);

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const statusRef = React.useRef<any>(null);
  const userIdRef = React.useRef<any>(null);
  const controlPointIdRef = React.useRef<any>(null);

  const [searchText, setSearchText] = useState<any>(
    searchParams.get('search') || ''
  );

  const [status, setStatus] = useState<any>(
    searchParams.get('status')
  );

  const [isActiveTodayPage, setIsActiveTodayPage] = useState<boolean>(!status)

  const [limitFilter, setLimitFilter] = useState<any>(
    searchParams.get('limit') || 5
  );

  const searchTextRef = React.useRef<any>(null);

  const [currentPage, setCurrentPage] = useState<any>(
    searchParams.get('current_page') || 1
  );

  const [options, setOptions] = useState([]);

  const [taskMetricData, setTaskMetricData] = useState<TaskMetric[]>([]);
  const [showShiftCheckTable, setShowShiftCheckTable] = useState<boolean>(false);

  const [changeRollForTaskMode, setChangeRollForTaskMode] = useState<boolean>(false)
  const [taskPrevRoll, setTaskPrevRoll] = useState('')

  const [selectedOptions, setSelectedOptions] = useState<any>(
    searchParams.get('options') || []
  );
  const [parsedSelectedOptions, setParsedSelectedOptions] = useState<any>([]);

  const selectedOptionsRef = React.useRef<any>(null); // every 30 second
  const dataRef = React.useRef<any>(null); // every 30 second

  const [hourTime, setHourTime] = useState<number>(0);
  const [secondTime, setSecondTime] = useState<number>(0);
  const [minuteTime, setMinuteTime] = useState<number>(0);

  const [timerStart, timerStop] = useState<boolean>(false);

  const selectedRoomRefSecond: any = useRef(0);
  const selectedRoomRefMinute: any = useRef(0);
  const selectedRoomRefHour: any = useRef(0);

  const setFilterOptions = (e: any) => {
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const optionElementId = optionElement.getAttribute('id');
    const dataOptionid = optionElement.getAttribute('data-option-id');
    if (optionElementId == null) {
      return;
    }

    if (parsedSelectedOptions.length === 0) {
      const firstObject = [
        {
          option_id: dataOptionid,
          id: optionElementId,
          value: e.target.value,
        },
      ];
      setParsedSelectedOptions(firstObject);

      searchParams.set('options', JSON.stringify(firstObject));
      setSearchParams(searchParams);
    } else {
      const isExist = parsedSelectedOptions.filter(
        (item: any) => item?.id === optionElementId
      );

      if (isExist?.length == 0) {
        const newObject = {
          option_id: dataOptionid,
          id: optionElementId,
          value: e.target.value,
        };
        searchParams.set(
          'options',
          JSON.stringify([...parsedSelectedOptions, newObject])
        );
        searchParams.set('current_page', '1');
        setParsedSelectedOptions([...parsedSelectedOptions, newObject]);
        setCurrentPage(1);
        setSearchParams(searchParams);
      }
    }
  };

  const removeOptionById = (id: any) => {
    setParsedSelectedOptions((parsedSelectedOptions: any) =>
      parsedSelectedOptions.filter((data: any) => data.id !== id)
    );

    const result = parsedSelectedOptions.filter((data: any) => data.id !== id);
    if (result.length === 0) {
      searchParams.delete('options');
      setSearchParams(searchParams);
    } else {
      searchParams.set('options', JSON.stringify(result));
      setSearchParams(searchParams);
    }
  };

  const prepareSearchParams = () => {
    statusRef.current = status;
    userIdRef.current = userId;
    controlPointIdRef.current = controlPointId;
    searchTextRef.current = searchText; 

    const _currentPage: any = searchParams.get('current_page') || 1;
    const _selectedOptions: any = searchParams.get('options') || [];

    let _currentIndex: any = 0;
    let _intCurrentPage: any;
    selectedOptionsRef.current = [];
    try {
      _intCurrentPage = parseInt(_currentPage);
      selectedOptionsRef.current = JSON.parse(_selectedOptions);
    } catch (err) {
      //console.error(err);
    }

    if (
      _intCurrentPage != null &&
      typeof _intCurrentPage != 'undefined' &&
      _intCurrentPage != ''
    ) {
      if (_intCurrentPage > 0) {
        const _limitFilter: any = (_intCurrentPage - 1) * limitFilter;
        _currentIndex = _limitFilter;
      }
    }
    
    return {offset: _currentIndex, limitFilter, searchText: searchTextRef.current}
  }

  
  const loadShiftReportData = async () => {
    const {offset, limitFilter, searchText} = prepareSearchParams()

    setLoader(true);

    const currentShiftStartDateTime = new Date()
    currentShiftStartDateTime.setHours(ShiftsTimeLino[channelWork].startHour)
    currentShiftStartDateTime.setMinutes(0)
    currentShiftStartDateTime.setSeconds(0)
    const currentShiftEndDateTime = new Date()
    currentShiftEndDateTime.setHours(ShiftsTimeLino[channelWork].endHour)
    currentShiftEndDateTime.setMinutes(0)
    currentShiftEndDateTime.setSeconds(0)

    const getTasksMetricsOptions: any = {
      // user_id: statusRef.current == "В ожидании" ? "" : userIdRef.current,
      control_point_id: controlPointIdRef.current,
      offset: offset,
      limit: limitFilter,
      shift: channelWork,
      end_time_gte: currentShiftStartDateTime.toISOString(),
      end_time_lte: currentShiftEndDateTime.toISOString(),
    }

     const response: any = await requestGetFetch(
      TASKS + 'get-metrics/',
      getTasksMetricsOptions,
      accessToken
    );
    if(response && response?.data?.length > 0) {
      setTaskMetricData(response.data)
    }
    
    setLoader(false);
  }

  const loadTasksData = async () => {
    const {offset, limitFilter, searchText} = prepareSearchParams()

    setLoader(true);
    let response;

    const getTasksOptions: any = {
      // user_id: statusRef.current == "В ожидании" ? "" : userIdRef.current,
      control_point_id: controlPointIdRef.current,
      offset: offset,
      limit: limitFilter,
      active_today: isActiveTodayPage,
      text: searchText,
    }

    if (!isActiveTodayPage) {
      getTasksOptions.status = statusRef.current
    } else {
      getTasksOptions.ordering = "status"
    }

    if (selectedOptionsRef.current?.length === 0) {
      response = await requestGetFetchByOptions(
        TASKS + 'get-tasks/',
        getTasksOptions,
        {},
        accessToken
      );
    } else {
      const newOptionsParsedInt = selectedOptionsRef.current?.map(
        (value: any) => {
          return {
            id: parseInt(value?.option_id),
            value: value?.value,
          };
        }
      );

      response = await requestGetFetchByOptions(
        TASKS + 'get-tasks/',
        getTasksOptions,
        newOptionsParsedInt,
        accessToken
      );
    }

    if (response?.error?.length > 0) {
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    if (response?.data?.tasks?.length > 0) {
      setData(response?.data?.tasks);
      dataRef.current = data;

      setTotalItems(response?.count);
      setOptions(response?.data?.options);

      const totalPagesCustom = Math.ceil(response?.count / limitFilter);
      setTotalPages(totalPagesCustom);
    } else {
      setData([]);
      dataRef.current = [];
    }
    setLoader(false);
  };

  const handleSearchText = (e: any) => {
    const { target } = e;
    setCurrentPage(1);
    searchParams.set('current_page', '1');
    if (target.value !== null && target.value !== '') {
      searchParams.set('search', target.value);
      searchParams.delete('page');
      setSearchText(target.value);
    } else {
      searchParams.delete('search');
      setSearchText('');
    }
    setSearchParams(searchParams);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    searchParams.set('current_page', page.toString());
    setSearchParams(searchParams);
  };

  const handleLimitSelect = (data: any) => {
    setLimitFilter(data);
    searchParams.set('limit', data);
    setSearchParams(searchParams);
  };

  const updateData = (newData: any) => {
    if (dataRef.current == null) {
      return;
    }

    if (dataRef.current?.length == 0) {
      return;
    }

    const updatedMessage = dataRef.current.map((value: any, index: any) => {
      if (
        value?.id === newData?.id &&
        value?.is_ready_to_start !== newData.is_ready_to_start
      ) {
        return { ...value, is_ready_to_start: newData.is_ready_to_start };
      }
      return value;
    });

    if (updatedMessage?.length > 0) {
      setData(updatedMessage);
    }
  };

  useEffect(() => {
    if (selectedOptions?.length > 0 && typeof selectedOptions !== 'undefined') {
      setParsedSelectedOptions(JSON.parse(selectedOptions));
    }
  }, [selectedOptions]);

  useEffect(() => {
    // Вызов при первом заходе
    if (showShiftCheckTable){
      loadShiftReportData();
    } else {
      loadTasksData();
    }

    const intervalId = setInterval(() => {
      if (!showShiftCheckTable && hasMirror) {
        loadTasksData();
      }
    }, 15000); // 15000 миллисекунд = 15 секунд

    return () => clearInterval(intervalId);
  }, [limitFilter, status, searchParams, accessToken, hasMirror]);

  useEffect(() => {
    if (typeof selectedData != 'undefined') {
      if (selectedData.execution_time != null) {
        const result = timestampToDateTime(selectedData.execution_time);
        let splitTime = result.split(':');

        if (splitTime.length == 2) {
          splitTime.unshift('00');
        }

        if (splitTime.length > 0) {
          selectedRoomRefHour.current = parseInt(splitTime[0]);
          selectedRoomRefMinute.current = parseInt(splitTime[1]);
          selectedRoomRefSecond.current = parseInt(splitTime[2]);
          setHourTime(parseInt(splitTime[0]));
          setMinuteTime(parseInt(splitTime[1]));
          setSecondTime(parseInt(splitTime[2]));
        }
      }
    }
  }, [selectedData]);

  useEffect(() => {
    const es = new EventSource(
      `${MAIN_URL}${TASKS}event-stream-status?control_point_id=${controlPointId}`
    );
    es.onopen = () => {
      // console.log(">>> Connection opened!")
    };
    es.onerror = (e) => console.log('ERROR!', e);
    es.onmessage = (e) => {
      const parsedData = JSON.parse(e.data);
      if (parsedData?.length == 0) {
        return;
      }
      for (let i = 0; i < parsedData.length; i++) {
        updateData(parsedData[i]);
      }
    };
    return () => es.close();
  }, []);

  const [chooseMaterialForTask, setChooseMaterialForTask] = useState({
    showChooseModal: false,
    taskId: '',
  });

  const moveToTaskAction = (taskId: string) => {
    if (status === TASK_STATUSES.WAITING || status === TASK_STATUSES.DONE) {
      setTypeRoute('start');
    }

    if (status === TASK_STATUSES.IN_PROGRESS) {
      setTypeRoute('continue');
    }

    navigate(`/task-details/${taskId}`);
  };

  const taskButtonOnClick = (taskId: string) => {
    let currentCacheString: any =
      localStorage.getItem('activeMaterialsForControlPoints') || '';
    const currentCache = { ...JSON.parse(currentCacheString) };
    if (currentCache && !currentCache[controlPointId]?.activeMaterialId) {
      setChangeRollForTaskMode(false)
      setChooseMaterialForTask({
        showChooseModal: true,
        taskId: taskId,
      });
    } else {
      moveToTaskAction(taskId);
    }
  };

  const changeRollForTaskOnClick = (taskId: number, currentMaterialId: number) => {
    setChangeRollForTaskMode(true) 
    setChooseMaterialForTask({showChooseModal: true, taskId: taskId?.toString()})
    setTaskPrevRoll(currentMaterialId?.toString())
  }

  return (
    <div className="w-full flex flex-col h-full mt-[59px]">
      {taskDetailsContainerShow && (
        <TaskDetails
          taskDetailsContainerShow={taskDetailsContainerShow}
          setTaskDetailsContainerShow={setTaskDetailsContainerShow}
          selectedData={selectedData}
          // startTimer={startTimer}
          secondTime={secondTime}
          minuteTime={minuteTime}
          hourTime={hourTime}
          timerStart={timerStart}
          // cancelTimer={cancelTimer}
          userId={userId}
          controlPointId={controlPointId}
          status={status}
          hasMirror={hasMirror}
        />
      )}

      <div
        className={
          'w-full flex flex-col h-full ' +
          (taskDetailsContainerShow ? 'opacity-50' : 'opacity-100')
        }
      >
        {/* <div className="w-full h-[200px]   px-[52px] flex items-center  text-[20px] font-nunito font-medium bg-white">
            <div>Обзор задач</div>
          </div> */}
        <div className="bg-[#F2F3F5] w-full flex flex-col gap-[16px] items-center text-[20px] font-nunito font-medium h-full">
          {/* Start Filter Container */}
          {!hasMirror && !showShiftCheckTable && (
            <TaskFilterContainer
              searchText={searchText}
              setSearchText={setSearchText}
              handleSearchText={handleSearchText}
              options={options}
              setFilterOptions={setFilterOptions}
              parsedSelectedOptions={parsedSelectedOptions}
              removeOptionById={removeOptionById}
              setParsedSelectedOptions={setParsedSelectedOptions}
              setSearchParams={setSearchParams}
            />
          )}

          {/* End Filter Container */}

          <div className="w-full bg-white  flex flex-col justify-start items-start gap-[12px] p-[16px]   text-[20px] font-nunito font-medium">
            {/* Tab filters */}
            <Tab
              status={status}
              setStatus={setStatus}
              isShowTodayTasks={isActiveTodayPage}
              setShowTodayTasks={setIsActiveTodayPage}
              isShowShiftCheck={showShiftCheckTable}
              setShowShiftCheck={setShowShiftCheckTable}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />

            {chooseMaterialForTask.showChooseModal && (
              <>
                <NewRollModal
                  newRollModalShow={chooseMaterialForTask.showChooseModal}
                  isChangeRollForTaskMode={changeRollForTaskMode}
                  prevMaterialId={taskPrevRoll}
                  setNewRollModalShow={(newRollModalShow: boolean) =>
                    setChooseMaterialForTask({
                      showChooseModal: newRollModalShow,
                      taskId: chooseMaterialForTask.taskId,
                    })
                  }
                  rollSubmitParams={{
                    taskId: chooseMaterialForTask.taskId,
                    status: 'started',
                    length: 0,
                  }}
                  currentTaskId={chooseMaterialForTask.taskId}
                  setNewRollSubmitResult={() => {}}
                  onSubmit={(selectedRollId: string) => {
                    if(!changeRollForTaskMode){
                      writeToCache(
                        selectedRollId,
                        chooseMaterialForTask.taskId,
                        controlPointId
                      );
                      setChooseMaterialForTask({
                        showChooseModal: false,
                        taskId: chooseMaterialForTask.taskId,
                      });
                      moveToTaskAction(chooseMaterialForTask.taskId);
                    } else {
                      setChooseMaterialForTask({
                        showChooseModal: false,
                        taskId: chooseMaterialForTask.taskId,
                      });
                      setChangeRollForTaskMode(false)
                    }
                  }}
                  loadDataTaskData={changeRollForTaskMode ? loadShiftReportData : loadTasksData}
                />
              </>
            )}

            {showShiftCheckTable ? (<ShiftCheckTable
              data={taskMetricData}
              loader={loader}
              taskButtonOnClick={changeRollForTaskOnClick}
              options={[{id: 13, name: 'Заказ'},{id: 12, name: 'Машина'},{id: 14, name: 'Пачка'}]}
            />) : (<Table
              data={data}
              options={options}
              loader={loader}
              status={status}
              setTypeRoute={setTypeRoute}
              setSelectedData={setSelectedData}
              setTaskDetailsContainerShow={setTaskDetailsContainerShow}
              taskDetailsContainerShow={taskDetailsContainerShow}
              hasMirror={hasMirror}
              taskButtonOnClick={taskButtonOnClick}
            />)}

            {totalPages > 1 && (
              <ProjectPagination
                currentPage={currentPage}
                totalItems={totalItems}
                pageChange={handlePageChange}
                itemsPerPage={limitFilter}
                limitItemsOptions={limitItemsOptions}
                handleLimitSelect={handleLimitSelect}
                limitFilter={limitFilter}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
