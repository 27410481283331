import React, { useEffect, useState } from 'react';
import SelectNew from '../../components/SelectNew';
import { useAuth } from '../../hooks/useAuth';
import { requestGetFetch, requestPost } from '../../api/request';
import { MATERIALS, TASKS } from '../../api/url';
import { toast } from 'react-toastify';
import {
  setActiveMaterialToCache,
  transformedMaterialsOptions,
} from '../../utils/functions';
import Modal from '../../components/Modal';
import Button from '../../components/buttons/Button';

export type RollSetupParams = {
  taskId: string | undefined;
  status: string | undefined;
};

const NewRollModal = ({
  newRollModalShow,
  isChangeRollForTaskMode,
  prevMaterialId,
  setNewRollModalShow,
  onSubmit,
  setNewRollSubmitResult,
  currentTaskId,
  rollSubmitParams,
  loadDataTaskData,
  currentItemThickness,
}:
  | any
  | {
    newRollModalShow: boolean;
    isChangeRollForTaskMode: boolean;
    prevMaterialId?: number;
    setNewRollModalShow: (newRollModalShow: boolean) => void;
    onSubmit: (selectedMaterialId: string) => Promise<void>;
    setNewRollSubmitResult: (newRoll?: any) => void;
    currentTaskId: string;
    rollSubmitParams: RollSetupParams;
    loadDataTaskData: () => Promise<void>;
    currentItemThickness?: number;
  }) => {
  const {
    accessToken,
    refreshToken,
    userId,
    controlPointId,
    channelWork,
    typeRoute,
  }: any = useAuth();

  const [selectedRoll, setSelectedRoll] = useState<any>(null);

  const [materialsData, setMaterialsData] = useState<any>([]);

  const [searchQuery, setSearchQuery] = useState<string>('');

  /**
   * Получить список рулонов
   */
  const [isLoading, setIsLoading] = useState(false);
  const [isIntersect, setIsIntersect] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 100;

  const fetchMaterials = async (isInitialLoad = true) => {
    setIsLoading(true);
    const currentOffset = isInitialLoad ? 0 : materialsData.length;

    const response = await requestGetFetch(
      MATERIALS,
      { limit, offset: currentOffset, description: searchQuery },
      accessToken
    );

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    } else if (response?.data?.length > 0) {
      if (isInitialLoad) {
        setMaterialsData(response.data);
      } else {
        setMaterialsData((prevMaterials: any) => [
          ...prevMaterials,
          ...response.data,
        ]);
        setOffset(currentOffset);
      }
    } else if (isInitialLoad) {
      setMaterialsData([]);
      setSelectedRoll(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchMaterials();
  }, [newRollModalShow, searchQuery]);

  useEffect(() => {
    if (isIntersect) {
      fetchMaterials(false);
    }
  }, [isIntersect]);


  /**
   * Заменить рулон у задачи
   */
  const changeRollForTask = async () => {
    const response = await requestPost(
      TASKS + rollSubmitParams?.taskId + '/change-material/',
      {
        current_material_id: prevMaterialId,
        new_material_id: selectedRoll?.id,
      },
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    loadDataTaskData && await loadDataTaskData();
  }

  /**
   * Продолжить с новым рулоном
   */
  const continueWithNewRoll = async ({ taskId, status }: RollSetupParams) => {
    const response = await requestPost(
      MATERIALS + 'use-material/',
      {
        task_id: taskId,
        material_id: selectedRoll?.id,
        status: status,
      },
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    setActiveMaterialToCache({
      controlPointId,
      activeMaterialId: selectedRoll?.id,
      activeTaskId: currentTaskId,
    });

    setNewRollSubmitResult(response?.data);

    loadDataTaskData && await loadDataTaskData();
  };

  const [isThicknessCheckModalOpen, setIsThicknessCheckModalOpen] =
    useState(false);

  const handleContinueWithNewRoll = async () => {
    if (!isChangeRollForTaskMode) {
      await continueWithNewRoll(rollSubmitParams);
      await onSubmit(selectedRoll?.id);
    } else {
      await changeRollForTask();
      await onSubmit(selectedRoll?.id);
    }
  };

  return (
    <div className="text-[32px] fixed inset-0 bg-black bg-opacity-50 z-40">
      <div className="fixed inset-0 z-50 flex flex-col items-center">
        <div
          className="bg-white relative"
          style={{
            width: '580px',
            minHeight: '332px',
            padding: '32px',
            borderRadius: '8px',
          }}
        >
          {isChangeRollForTaskMode ? 'Изменение рулона у задачи' :'Возобновление работы'}
          <div className="text-[20px] mt-[24px]">
            Выберите {isChangeRollForTaskMode ?
              'новый рулон' :
              'рулон для проката'}
            <SelectNew
              options={transformedMaterialsOptions(materialsData)}
              onChange={(option) => setSelectedRoll(option)}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              setIsIntersect={setIsIntersect}
              virtualKeyboard
            />
          </div>
          <div className="flex w-full gap-[8px] mt-[32px]">
            <Button
              text="Отменить"
              color="secondary"
              onClick={() => {
                setSelectedRoll(null);
                setNewRollModalShow(!newRollModalShow);
              }}
            />
            <Button
              text="Подтвердить"
              onClick={() => {
                if (!isChangeRollForTaskMode && currentItemThickness !== selectedRoll?.thickness) {
                  setIsThicknessCheckModalOpen(true);
                } else {
                  handleContinueWithNewRoll();
                }
              }}
              disabled={!selectedRoll}
            />

            <Modal
              isOpen={isThicknessCheckModalOpen}
              onClose={() => setIsThicknessCheckModalOpen(false)}
              children={
                <div className="text-center">
                  <div className="text-[32px] text-center">
                    Толщина рулона и листа не совпадают. Продолжить?
                  </div>

                  <div className="flex w-full gap-[8px] mt-[32px] justify-center">
                    <Button
                      text="Нет"
                      color="secondary"
                      onClick={() => {
                        setIsThicknessCheckModalOpen(false);
                      }}
                    />

                    <Button
                      text="Да"
                      onClick={() => handleContinueWithNewRoll()}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRollModal;
